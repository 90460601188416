<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      width="320"
      style="max-width: 90vw; height: 100%"
      :temporary="$vuetify.breakpoint.mdAndDown || sideCollapse"
      :permanent="$vuetify.breakpoint.lgAndUp && !sideCollapse"
      class="elevation-2"
    >
      <sidebar-wrapper />
    </v-navigation-drawer>
    <v-app-bar
      app
      fixed
      elevate-on-scroll
      clipped-left
      :height="56"
      v-show="$vuetify.breakpoint.mdAndDown"
    >
      <template v-if="$vuetify.breakpoint.mdAndDown || sideCollapse">
        <v-app-bar-nav-icon color="primary" @click.stop="drawer = !drawer" />
      </template>
      <header-logo height="24" :light="false" />

      <v-spacer />

      <profile-summary name />
    </v-app-bar>
  </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo";
import ProfileSummary from "./ProfileSummary";
import SidebarWrapper from "../../Questionnaire/Sidebar/SidebarWrapper";

export default {
  name: "HeaderForDocumentGeneration",
  components: { SidebarWrapper, ProfileSummary, HeaderLogo },
  data: () => ({
    drawer: false,
  }),
  computed: {
    sideCollapse() {
      return this.$route.meta.sideCollapse;
    },
  },
};
</script>
