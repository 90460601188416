var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex align-items-center cursor-pointer"},on),[_c('avatar',_vm._b({staticClass:"th-mr-16",attrs:{"keep-alive":""}},'avatar',attrs,false))],1)]}}])},[_c('div',{staticClass:"th-py-20 th-px-12 bg-white",staticStyle:{"min-width":"200px"}},[_vm._t("default"),_vm._l((_vm.profileMenu),function(ref,i){
var icon = ref[0];
var title = ref[1];
var link = ref[2];
return _c('router-link',{key:i,staticClass:"d-block th-mb-16 f-button text-decoration-none",attrs:{"to":link}},[_c('h6',{staticClass:"subtitle1 th-m-0 text-center",domProps:{"innerHTML":_vm._s(_vm.$translation.t(title))}})])}),_c('v-btn',{attrs:{"block":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Logout"))+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }