<template>
  <div v-if="show" class="d-flex align-items-center">
    <order-plan-button class="d-none d-lg-inline-block th-mr-16" sm />
    <header-profile-menu :name="name" class="th-mx-16" />
  </div>
</template>
<script>
import HeaderProfileMenu from "@/components/Layout/Header/HeaderProfileMenu";
import OrderPlanButton from "@/components/Billing/OrderPlanButton";

export default {
  name: "profile-summary",
  components: { HeaderProfileMenu, OrderPlanButton },
  props: {
    name: Boolean,
  },
  computed: {
    show() {
      return !this.$route.meta.noProfile;
    },
  },
};
</script>
