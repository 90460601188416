<template>
  <div class="question-steps__container">
    <div class="text-center th-py-24">
      <header-logo height="24" :light="false" />
    </div>
    <component :is="sideMenuComponent" />
  </div>
</template>

<script>
import HeaderLogo from "../../Layout/Header/HeaderLogo";
export default {
  name: "SidebarWrapper",
  components: { HeaderLogo },
  computed: {
    sideMenuComponent() {
      try {
        let matchedRoutesSideMenuComponent = this.$route.matched.map(
          ({ meta }) => meta.sideMenuComponent
        );

        matchedRoutesSideMenuComponent.push(
          this.$route?.meta?.sideMenuComponent
        );

        matchedRoutesSideMenuComponent = matchedRoutesSideMenuComponent.filter(
          (layout) => !!layout
        );

        let selectedSideMenuComponent = matchedRoutesSideMenuComponent.pop();

        if (selectedSideMenuComponent === false) return null;

        if (selectedSideMenuComponent) {
          return selectedSideMenuComponent;
        }
      } catch (e) {
        this.$log.notify(e);
      }

      return () => import("./QuestionSteps");
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/components/question-steps";

.question-steps {
  $self: &;

  &__step,
  &__sub-step {
    padding: 12px 16px;
    color: $text-primary;
    position: relative;
    @include f-subtitle1();
    font-weight: 400 !important;

    > * {
      z-index: 1;
    }

    &.done,
    &.visited {
      cursor: pointer;
    }

    &.active {
      color: $primary-mid;
      background: $primary-light;
      opacity: 1;
    }

    &.done {
      color: $text-primary;
      opacity: 1;
    }

    &.err {
      color: $danger;
      font-weight: 600;
    }

    &.active.err {
      background: $red-light;
    }

    &.visited:not(.done):not(.active):not(.err) #{$self}__step-count {
      background: $white !important;
      color: $primary-mid !important;
      border: 1px solid $primary-mid !important;

      span {
        color: $primary-mid !important;
      }
    }

    &.active #{$self}__step-count {
      background: $primary-mid !important;
      border: none !important;

      &,
      span {
        color: $white !important;
      }
    }

    &.done #{$self}__step-count {
      background: $success !important;
      border: none !important;

      &,
      span {
        color: $white !important;
      }
    }

    &.err #{$self}__step-count {
      background: $danger !important;
      border: none !important;

      &,
      span {
        color: $white !important;
      }
    }
  }

  &__sub-step {
    padding-left: 32px;
    cursor: pointer;

    &:hover {
      background: rgba(4, 5, 46, 0.16);
      opacity: 0.9;
    }
  }
}
</style>
