<template>
  <!--  <th-badge-->
  <!--    color="primary"-->
  <!--    solid-->
  <!--    v-bind="$attrs"-->
  <!--    v-if="getCurrentSubscriptionName"-->
  <!--  >-->
  <!--    {{ $translation.t(getCurrentSubscriptionName) }}-->
  <!--  </th-badge>-->
  <div></div>
</template>

<script>
// import { mapGetters } from "vuex";
// import ThBadge from "@/components/GeneralComponents/ThBadge";

export default {
  name: "OrderPlanButton",
  // components: { ThBadge },
  computed: {
    // ...mapGetters(["getCurrentSubscriptionName"]),
  },
};
</script>
