<template>
  <div class="default">
    <header-for-document-generation />
    <v-main>
      <div class="container">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
import HeaderForDocumentGeneration from "../components/Layout/Header/HeaderForDocumentGeneration";

export default {
  name: "Generation",
  components: {
    HeaderForDocumentGeneration,
  },
};
</script>

<style lang="scss" scoped>
.subheader {
  height: $subheader-height;
  display: flex;
  align-items: center;
}
</style>
