<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-items-center cursor-pointer" v-on="on">
        <avatar v-bind="attrs" class="th-mr-16" keep-alive />
      </div>
    </template>
    <div class="th-py-20 th-px-12 bg-white" style="min-width: 200px">
      <slot />
      <router-link
        v-for="([icon, title, link], i) in profileMenu"
        :key="i"
        :to="link"
        class="d-block th-mb-16 f-button text-decoration-none"
      >
        <!--                <v-icon class="th-mr-8">{{ icon }}</v-icon>-->
        <h6
          class="subtitle1 th-m-0 text-center"
          v-html="$translation.t(title)"
        ></h6>
      </router-link>
      <v-btn block color="primary" outlined @click="$store.dispatch('logout')">
        {{ $translation.t("Logout") }}
      </v-btn>
    </div>
  </v-menu>
</template>
<script>
import Avatar from "../../Client/Avatar";

export default {
  name: "header-profile-menu",
  components: { Avatar },
  props: {
    name: Boolean,
  },
  computed: {
    profileMenu() {
      return [
        [
          "mdi-account",
          "Profile",
          { name: "settings", params: { ...this.$route.params } },
        ],
        [
          "mdi-account",
          "Billing",
          { name: "billing", params: { ...this.$route.params } },
        ],
      ];
    },
  },
};
</script>
